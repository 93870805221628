import React, { Component } from "react";
import './ErrorNotFound.css';

export default class ErrorNotFound extends Component {
    state = {
        text: "404. No encontrada"
    }

    render (){
        return (
            <div className="error404Container">
                <div className="errorText">{this.state.text}</div>
            </div>
        )
    }
}
