import React, { Component } from 'react';
import './Wishlist.css';

export default class Wishlist extends Component {
	render (){
        return(
            <div className='wishlist-container'>
                <table className="w-full text-sm text-left table-fixed">
                    <thead>
                        <tr className='text-s'>
                            <td className='px-6 py-3 wish-title'>Qué?</td>
                            <td className='px-6 py-3 wish-title'>Dónde?</td>
                            <td className='px-6 py-3 wish-title'>Quién?</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="border-b dark:bg-gray-800 dark:border-gray-700">
                            <td className='px-6 py-4'><strike>Rastrillo</strike></td>
                            <td className='px-6 py-4'><a href="https://www.sodimac.cl/sodimac-cl/product/796662/rastrillo-14-dientes-de-metal-negro/796662" alt="Rastrillo" target="_blank">Sodimac</a></td>
                            <td className='px-6 py-4'>Gracias Hacienda</td>
                        </tr>
                        <tr className="border-b dark:bg-gray-800 dark:border-gray-700">
                            <td className='px-6 py-4'><strike>Serrucho para Jardín</strike></td>
                            <td className='px-6 py-4'><a href="https://www.sodimac.cl/sodimac-cl/product/1164759/serrucho-para-jardin-de-acero-cafe/1164759" alt="Serra Podar" target="_blank">Sodimac</a></td>
                            <td className='px-6 py-4'>Gracias kp</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }    
}