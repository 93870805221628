import './Kuotes.css'

import React, { Component } from 'react';
import axios from "axios";

// import { useAsync } from "react-async"


export default class Kuotes extends Component {
	state = {
		text: "En toda clase de entrenamiento el punto más importante es librarse de las malas costumbres.",
		author: "Jigoro Kano"
	}

	constructor(props){
		super(props)
		this.intervalSeconds = 10
	}

	componentWillUnmount() {
	}
	componentDidMount() {
		this.interval = setInterval(() => obtenerData(), this.intervalSeconds * 1000);

		const obtenerData = async() => {
			const url = 'https://api.kope.cl/getQuote';
			await axios.get( url )
				.then( (response) => {
					this.setState({
						text: response.data.data.quote_text,
						author: response.data.data.quote_author
					});
				})
				.catch( () => {
					console.log("NO funciona =(");
				});
		}
		obtenerData();
	}

	render (){
		return (
			<div className="quote">
				<div className="quote-text">{this.state.text}</div>
				<div className="quote-author">-- {this.state.author}</div>
			</div>
		)
	}
}
