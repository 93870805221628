import React, { Component } from "react";
import axios from 'axios'
import './Album.css';
import Title from '../Layout/Title';

export default class Album extends Component {
    state = {
        'title': "",
        'photos': [],
        'album': {}
    }

    obtenerData = async () => {
        const url = 'https://api.kope.cl/getPhotosByAlbumWithToken';
        try {
            localStorage.setItem('restrictedPath', window.location.href);
            var baseUrl = (window.location).href
            var album_id = baseUrl.substring(baseUrl.lastIndexOf('/') + 1);
            const response = await axios.post(url, {
                token: localStorage.getItem('authToken'),
                album_id: album_id
            });
            console.log(response);
            if (response.data.code !== 200) {
                localStorage.removeItem('authToken')
                window.location.href = '/login';
            }

            this.setState({
                album: response.data.data.album,
                photos: Object.values(response.data.data.photos)
            });

        } catch (error) {
            console.error('Error al obtener los datos: ', error)
        }

    };

    componentDidMount() {
        (async () => {
            await this.obtenerData();
        })();
    }

    /*
    componentDidMount() {
        const obtenerData = async() => {
            var baseUrl = (window.location).href
            var album_id = baseUrl.substring(baseUrl.lastIndexOf('/') + 1);
            const url = 'https://api.kope.cl/public/getPhotosByAlbum/'+album_id;
            await axios.get( url )
                .then( (response) => {
                    this.setState({
                        "photos": response.data.data.photos,
                        "album": response.data.data.album
                    });
                })
                .catch( error => {
                    console.log("=( NO conecta con API");
                    console.log(error);
                });
        }
        obtenerData();
    }*/

    
    render() {
        var modal            = document.getElementById("modal");
        var modalImg         = document.getElementById("modal-img");
        var modalDescription = document.getElementById("modal-description");

        const showModal = (url, description) => {
            modal.classList.remove('hidden');
            modalImg.src = url;
            modalDescription.innerText = description;
        }

        const closeModal = (e) => {
            e.preventDefault();
            modal.classList.add('hidden');
        }

        return(
            <div>
            <Title dataFromParent = {this.state.album.name} />
                <div className="photosContainer">
                    <div className="descriptionContainer">
                        <div className="descriptionText text-right">{this.state.album.description}</div>
                    </div>                

                    <section className="overflow-hidden text-gray-700 ">
                    <div className="container px-5 py-2 mx-auto lg:pt-12 lg:px-32">
                        <div className="flex flex-wrap -m-1 md:-m-2">
                            {
                                this.state.photos.map(
                                    (photo) => (
                                        <div className="flex flex-wrap w-1/3" key={photo.id}>
                                            <div className="w-full p-1 md:p-2">
                                                <img 
                                                    alt="gallery" 
                                                    className="block object-cover object-center w-full h-full rounded-lg hover:scale-105"
                                                    src={'https://web.kope.cl/scripts/phpThumb.php?w=300&src=' + photo.url} 
                                                    onClick={ () => showModal (photo.url, photo.description) }
                                                    />
                                            </div>
                                        </div>
                                    )
                                )
                            }
                            </div>
                        </div>
                    </section>
                </div>
                <div id="modal" className="hidden fixed top-0 left-0 z-80 w-screen h-screen bg-black/70 flex justify-center items-center">
                    <a className="fixed z-80 top-6 right-8 text-white text-5xl font-bold" href="/#" onClick={ e => closeModal(e) }>
                        &times;
                    </a>
                    <img id="modal-img" className="max-w-[800px] max-h-[600px] object-cover" alt="" />
                    <div className="absolute flex justify-center items-center z-90 max-w-[600px] max-h-[400px]">
                        <p className="text-2xl font-bold text-white text text-border justify-center" id="modal-description">&nbsp;</p>
                    </div>
                </div>
            </div>
        )
    }
}
