import React, { Component } from 'react';
import './Title.css';

export default class Title extends Component {
state = {
    titleText: "Text Default"
}

render(){
    return (
        <div className='titleContainer'>
            <div className='titleTextHeader'>{this.props.dataFromParent}</div>
        </div>
    )
}

}