import React, { Component } from 'react';
import './Footer.css';
import axios from 'axios'

export default class Footer extends Component {
    state = {
        ipv4: "127.0.0.1",
        city: "Ciudad",
        country: "Pais"
    }
    componentDidMount() {
        const obtenerData = async() => {
            const url = 'https://api.kope.cl/ipInfo';
            await axios.get( url )
                .then( (response) => {
                    this.setState({
                        ipv4: response.data.data.IPv4,
                        city: response.data.data.city,
                        country: response.data.data.country
                    });
                })
                .catch( ()=>{
                    console.log("NO funciona =(");
                });
        }
        obtenerData();
    }
    render () {
        return (
            <div className="footer">
                kopE.cl &reg; 2006 - 2024<br />
                Desde: {this.state.ipv4} - {this.state.city}, {this.state.country}
            </div>
        )
    }
}
