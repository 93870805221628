import React, { Component } from 'react';
import axios                from 'axios'
import Title                from '../Layout/Title';


export default class Post extends Component {
    state = {
        post: {
            id: 0,
            title: "",
            content: "",
            created_at: ""
        },
        title: "tail -f /var/log/kope.log"
    }
    Post() {
    }

    componentDidMount() {
        const obtenerData = async() => {
            var baseUrl = (window.location).href
            var post_id = baseUrl.substring(baseUrl.lastIndexOf('/') + 1);
            const url = 'https://api.kope.cl/getPostById/'+post_id;
            await axios.get( url )
                .then( (response) => {
                    this.setState({
                        "post": response.data.data.post
                    });
                })
                .catch( error => {
                    console.log("=( NO conecta con API");
                    console.log(error);
                });
        }
        obtenerData();
    }

    render() {
        return(
            <div className="blogContainer">
                <Title dataFromParent={this.state.title}/>
                <div>
                    {
                        <div key={this.state.post.id} className="blogPost">
                            <div className="postTitle">{this.state.post.title}</div>
                            <div className="postContent" dangerouslySetInnerHTML={{ __html: this.state.post.content }}></div>
                            <div className='postDate'>{this.state.post.created_at}</div>
                        </div>
                    }
                </div>
            </div>
        )
    }

}