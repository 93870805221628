import React, { Component } from 'react';

import './Header.css';
import axios from "axios";

export default class Header extends Component {
    state = {
        located: "",
        listening: "",
        reading: ""
    }
    componentDidMount() {
        const obtenerData = async() => {
            const url = 'https://api.kope.cl/getPageCover';

            await axios.get( url )
                .then( (response) => {
                    this.setState({
                        located: response.data.data.located,
                        reading: response.data.data.reading,
                        listening: response.data.data.listening
                    });
                })
                .catch( ()=>{
                    console.log("NO funciona =(");
                });
        }
        obtenerData();
    }
    render () {
        return (
            <div className="header container mx-auto">
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6'>
                    <div>
                        <div className='grid grid-cols-2 heading-container'>
                            <div className="text-center font-bold">Ubicación</div>
                            <div className="text-right italic">{this.state.located}</div>
                            <div className="text-center font-bold">Leyendo</div>
                            <div className="text-right italic">{this.state.reading}</div>
                            <div className="text-center font-bold">Escuchando</div>
                            <div className="text-right italic">{this.state.listening}</div>
                        </div>
                    </div>
                    <div></div>
                    <div></div>
                    <div className='flex justify-center'>
                        <img className="rounded-xl" src="/images/avatar-transparente.png" alt="" />
                    </div>
                </div>
            </div>
        )
    }
}
