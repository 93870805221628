import React, { Component } from "react";
import { Link } from 'react-router-dom';
import Title from '../Layout/Title';
import axios from 'axios';
import './Photos.css';

export default class Photo extends Component {
    state = {
        'title': 'Fotos',
        'albums': []
    }
    constructor(props) {
        super(props);
        this.source = axios.CancelToken.source();
    }

    obtenerData = async () => {
        const url = 'https://api.kope.cl/getAlbumsWithToken';
        try {
            localStorage.setItem('restrictedPath', window.location.href);
            const response = await axios.post(url, {
                token: localStorage.getItem('authToken')
            });
            
            if (response.data.code !== 200) {
                localStorage.removeItem('authToken')
                window.location.href = '/login';
            }
            
            this.setState({
                albums: Object.values(response.data.data.albums)
            });
            
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Solicitud cancelada:', error.message);
            } else if (error.response) {
                console.error('Respuesta del servidor:', error.response.data);
            } else if (error.request) {
                console.error('No se recibió respuesta del servidor:', error.request);
            } else {
                console.error('Error:', error.message);
            }
        }
    };

    componentDidMount() {
        (async () => {
            await this.obtenerData();
        })();
    }
    
    render() {
        return(
            <div>
                <Title dataFromParent={this.state.title} />
                <div className="photosContainer">
                    {this.state.albums.map((album) => (
                        <Link to={`/album/${album.id}`} key={album.id}>
                            <div className="flex justify-center">
                                <div className="flex flex-col md:flex-row md:max-w-xl rounded-lg bg-black shadow-lg">
                                    <img className="w-full h-96 md:h-auto object-cover md:w-48 rounded-t-lg md:rounded-none md:rounded-l-lg" src={`https://web.kope.cl/scripts/phpThumb.php?w=300&src=${album.image_url}`} alt="" />
                                    <div className="p-6 flex flex-col justify-start">
                                        <h5 className="text-gray-200 text-xl font-medium mb-2">{album.name}</h5>
                                        <p className="text-gray-400 text-base mb-4">{album.description}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="min-height-20"></div>
                        </Link>
                    ))}
                    <div className="min-height-20"></div>
                </div>
            </div>
        )
    }
}
