import React, { Component } from 'react';
import './Blog.css';
import axios from 'axios'
import Title from '../Layout/Title';
import { Link } from 'react-router-dom';

export default class Blog extends Component {
    state = {
        'posts': [],
        'title': "tail -f /var/log/kope.log"
    }
    
    Blog() {
    }

    componentDidMount() {
        const obtenerData = async() => {
            const url = 'https://api.kope.cl/getBlog';
            await axios.get( url )
                .then( (response) => {
                    this.setState({
                        "posts": response.data.data.posts
                    });
                })
                .catch( error => {
                    console.log("=( NO conecta con API");
                    console.log(error);
                });
        }
        obtenerData();
    }

    render () {
        return (
            <div className="blogContainer">
                <Title dataFromParent={this.state.title} />
                <div>
                    {
                    this.state.posts.map((post) => (
                        <div key={post.id} className="blogPost">
                            <div className="postTitle">
                                <Link to={`blog/post/${post.id}`}>{post.title}</Link>
                            </div>
                            <div className="postContent" dangerouslySetInnerHTML={{ __html: post.content }}></div>
                            <div className='postDate'>{post.created_at}</div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}
